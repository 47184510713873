<template>
    <comp-form title="类型" ref="comp_form" submit-api="/gateway/api/sycompany/pc/label/createLabel" edit-api="/gateway/api/sycompany/pc/label/updateLabel" detail-api="/gateway/api/sycompany/pc/label/getLabelById" id-key="labelById" :submit-before="onSubmitBefore" @on-submit="onReset">
        <template v-slot:form-box="data">
            <FormItem label="类型名称">
                <Input style="width:300px" v-model.trim="data.form.labelName" :maxlength="50" placeholder="请填写类型名称"></Input>
            </FormItem>
            <FormItem label="排序">
                <Input style="width:300px" type="number" v-model="data.form.sort" :maxlength="50" placeholder="请填写排序"></Input>
            </FormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../components/CompForm.vue"

export default {
    components: { CompForm },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.labelType = "1"
            return data
        },
    },
}
</script>
